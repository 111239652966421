<template>
  <layout width="420">
    <template slot="header">
      Alarms
    </template>
    <template>
      <v-form>
        <object-item-alarms
          v-model="state"
          :type="itemType"
          :object-item-type="objectItemType"
        />
        <form-subheader title="Options" />
        <v-combobox
          v-model="state.tags"
          label="Optional tags to add to notification"
          multiple
          chips
        />
        <template v-for="(control, index) in controlsOnAlarm">
          <form-switcher
            :key="control.key"
            v-model="state[control.key].enabled"
            :title="control.title"
          />
          <template v-if="state[control.key].enabled">
            <v-select
              :key="`${control.key}_objectId`"
              v-model="state[control.key].objectId"
              label="Object name"
              item-value="id"
              item-text="name"
              :items="sources"
              clearable
              @input="handleInputControlAlarmObjectId(index, $event || '')"
              @click:clear="handleInputControlAlarmObjectId(index, '')"
            />
            <v-select
              :key="`${control.key}_rpc`"
              v-model="state[control.key].rpc"
              label="Control name"
              item-value="rpc"
              item-text="description"
              :items="control.controls"
              clearable
              @input="handleInputControlAlarmRpc(index, $event || '')"
              @click:clear="handleClearControlAlarmRpc(index, '')"
            />
            <template
              v-if="control.controlArguments && control.controlArguments.length"
            >
              <form-subheader
                :key="`${control.key}_parameters`"
                title="Parameters"
                class="text--secondary"
              />
              <v-text-field
                v-for="argument in control.controlArguments"
                :key="argument"
                v-model="state[control.key].arguments[argument]"
                :label="argument"
                class="mr-4"
              />
            </template>
          </template>
        </template>
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { ref, onBeforeMount, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { objectPropertyService, objectService } from '@/modules/common/api';
import { prepareObject } from '@/provider/utils';
import ObjectItemAlarms from './ObjectItemAlarms.vue';
import Layout from '@/components/popup/PopupLayoutDefault';
import {
  linkedObjectService,
  linkedObjectsService
} from '@/modules/linked-objects/api';
import {
  defaultGeoAlarm,
  defaultMonitoringAlarm,
  objectItemTypes
} from '@/modules/objects/ui/object-card/general/item-helpers';

export default {
  name: 'ObjectItemAlarmsEdit',
  components: {
    ObjectItemAlarms,
    Layout
  },
  props: {
    objectItemId: {
      type: String,
      default: ''
    },
    objectId: {
      type: String,
      default: ''
    },
    objectItemType: {
      type: String,
      default: objectItemTypes.monitoringItem
    }
  },
  setup(props, { emit }) {
    const state = ref({
      tags: [],
      controlAlarmOff: {
        enabled: false,
        objectId: '',
        rpc: '',
        arguments: {}
      },
      controlAlarmOn: {
        enabled: false,
        objectId: '',
        rpc: '',
        arguments: {}
      },
      alarms: []
    });
    const objectItem = ref({});
    const sources = ref([]);
    const controlsOnAlarm = ref([
      {
        key: 'controlAlarmOn',
        title: 'Execute Control on alarm on',
        controls: [],
        controlArguments: []
      },
      {
        key: 'controlAlarmOff',
        title: 'Execute Control on alarm off',
        controls: [],
        controlArguments: []
      }
    ]);

    const handleSubmit = async () => {
      await objectPropertyService.updateValues([
        {
          id: objectItem.value.stateAlarms?.id,
          value: state.value.alarms.filter(
            alarm => !!alarm?.condition?.operator
          )
        },
        {
          id: objectItem.value.stateTags?.id,
          value: state.value.tags
        },
        {
          id: objectItem.value.stateControlAlarmOff?.id,
          value: state.value.controlAlarmOff
        },
        {
          id: objectItem.value.stateControlAlarmOn?.id,
          value: state.value.controlAlarmOn
        }
      ]);
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onBeforeMount(async () => {
      objectItem.value = prepareObject(
        await objectService.fetch(props.objectItemId)
      );

      sources.value = await linkedObjectsService.fetch(props.objectId);
      const alarmCount = objectItem.value.commonAlertCount.value;
      for (let i = 0; i < alarmCount; i++) {
        state.value.alarms.push({
          ...(props.objectItemType === objectItemTypes.monitoringItem
            ? defaultMonitoringAlarm()
            : defaultGeoAlarm()),
          ...(objectItem.value.stateAlarms?.value?.[i] || {})
        });
      }
      state.value.tags = objectItem.value.stateTags?.value || [];
      state.value.controlAlarmOff = {
        ...state.value.controlAlarmOff,
        ...(objectItem.value.stateControlAlarmOff?.value || {})
      };
      state.value.controlAlarmOn = {
        ...state.value.controlAlarmOn,
        ...(objectItem.value.stateControlAlarmOn?.value || {})
      };

      controlsOnAlarm.value.forEach(async control => {
        const { objectId, rpc } = state.value[control.key];
        if (objectId) {
          control.controls = await getControlsByObjectId(objectId);
        }
        if (rpc) {
          control.controlArguments = await getControlArguments(objectId, rpc);
        }
      });
    });

    const getControlsByObjectId = async objectId => {
      if (!objectId) {
        return [];
      }
      return (
        await linkedObjectService.fetchControlsByObjectId(objectId)
      ).schema.schemaControls.filter(control => control.isRpc);
    };
    const handleInputControlAlarmObjectId = async (index, objectId) => {
      const { key } = controlsOnAlarm.value[index];
      state.value[key].objectId = objectId;
      state.value[key].rpc = '';
      state.value[key].arguments = {};
      const controls = await getControlsByObjectId(objectId);

      controlsOnAlarm.value.splice(index, 1, {
        ...controlsOnAlarm.value[index],
        controls
      });
    };

    const getControlArguments = async (objectId, rpc) => {
      if (!objectId || !rpc) {
        return [];
      }
      return (
        await linkedObjectService.fetchControlsByObjectId(objectId)
      ).schema.schemaControls
        .filter(
          control => control.rpc === rpc && control.rpc !== control.argument
        )
        .map(i => i.argument);
    };

    const handleInputControlAlarmRpc = async (index, value) => {
      const { key } = controlsOnAlarm.value[index];

      state.value[key].rpc = value;
      state.value[key].arguments = {};

      const controlArguments = await getControlArguments(
        state.value[key].objectId,
        value
      );

      controlsOnAlarm.value.splice(index, 1, {
        ...controlsOnAlarm.value[index],
        controlArguments
      });
    };

    const itemType = computed(() => objectItem.value?.commonType?.value);

    return {
      state,
      sources,
      handleInputControlAlarmObjectId,
      handleInputControlAlarmRpc,
      loading,
      submit,
      controlsOnAlarm,
      itemType
    };
  }
};
</script>
